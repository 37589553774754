/* eslint-disable consistent-return */
export function isDefined(object) {
	return typeof object !== "undefined" && object !== null;
}

export function getLinkHref(object, linkName) {
	if (object && object._links && object._links[linkName]) {
		return object._links[linkName].href;
	}
}
