import { getLinkHref } from "./utils";

export default class ApiAccess {
	constructor($http) {
		this._$http = $http;
	}

	using(object) {
		const http = this._$http;

		function hasQueryParamsAlready(baseUrl) {
			const regexp = new RegExp(/\?/);
			return regexp.test(baseUrl);
		}

		function createUrl(baseUrl, params) {
			const queries = [];
			Object.keys(params).forEach((key) => {
				queries.push(`${key}=${params[key]}`);
			});
			return baseUrl + (hasQueryParamsAlready(baseUrl) ? "&" : "?") + queries.join("&");
		}

		function get(linkName, params, cancellationToken) {
			const baseUrl = getLinkHref(object, linkName);
			return http.get(createUrl(baseUrl, params), cancellationToken)
				.then(JSON.parse);
		}

		return { get };
	}
}
