import { isDefined, getLinkHref } from "./utils";
import PromiseCache from "./promiseCache";
import PatientCache from "./patientCache";

export default class FlowerChartProvider {
	constructor($http, patientOverviewProvider, storage, eventBus) {
		this._linkName = "patientConditionsChart";
		this._$http = $http;
		this._eventBus = eventBus;
		this._patientOverviewProvider = patientOverviewProvider;
		this.patientCache = new PatientCache(storage);
		this.promiseCache = new PromiseCache();
	}

	get(patientId) {
		const patientData = this.patientCache.getValue(patientId, this._linkName);
		if (isDefined(patientData)) {
			return Promise.resolve(patientData);
		}

		if (this.promiseCache.contains(patientId)) {
			return this.promiseCache.get(patientId);
		}

		return this._patientOverviewProvider.get(patientId)
			.then((patient) => {
				if (this.promiseCache.contains(patientId)) {
					return this.promiseCache.get(patientId);
				}
				const patientObjectHref = getLinkHref(patient, this._linkName);
				if (patientObjectHref) {
					const patientObjectPromise = this._$http.get(patientObjectHref)
						.then(this.finishFetchPatientData(patientId));

					this.promiseCache.put(patientId, patientObjectPromise);
					return patientObjectPromise;
				}
				return Promise.reject();
			});
	}

	finishFetchPatientData(patientId) {
		const promiseCache = this.promiseCache;
		const linkName = this._linkName;
		return (patientObject) => {
			promiseCache.clear(patientId);
			this.patientCache.cachePatientData(patientId, linkName, patientObject);
			return patientObject;
		};
	}

	evict(patientId) {
		this.patientCache.evict(patientId, this._linkName);
		this._eventBus.broadcast("flowerChartEvictedEvent", { patientId });
	}
}
