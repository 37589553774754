function getDefaultStorage() {
	return sessionStorage;
}

class Storage {
	constructor(storage = getDefaultStorage()) {
		this.storage = storage;
	}

	setItem = (name, value) => this.storage.setItem(name, value);

	getItem = (name) => this.storage.getItem(name);

	removeItem = (name) => this.storage.removeItem(name);

	clear = () => this.storage.clear();

	keys = () => Object.keys(this.storage);
}

export default Storage;
