import CompressingStorage from "./compressingStorage";
import Storage from "./storage";

function getDefaultStorage() {
	return sessionStorage;
}

class StorageFactory {
	static create(nativeStorage = getDefaultStorage()) {
		return CompressingStorage.isAvailable() ? new CompressingStorage(nativeStorage) : new Storage(nativeStorage);
	}
}

export default StorageFactory;
