import { isDefined } from "./utils";

export default class PromiseCache {
	constructor() {
		this.promiseCache = {};
	}

	contains(patientId) {
		return isDefined(this.promiseCache[patientId.toString()]);
	}

	put(patientId, promise) {
		this.promiseCache[patientId.toString()] = promise;
	}

	get(patientId) {
		return this.promiseCache[patientId.toString()];
	}

	clear(patientId) {
		delete this.promiseCache[patientId.toString()];
	}
}
