import BaseProvider from "./baseProvider";

export default class UrlBaseProvider extends BaseProvider {
	constructor(objectName, sourceUrl, $http, storage, eventBus) {
		super(objectName, storage, eventBus);
		this._$http = $http;
		this._sourceUrl = sourceUrl;
	}

	fetchObject() {
		return this._$http.get(this._sourceUrl);
	}
}
