const PATIENT_CACHE_NAME = "nexus_patient_cache_";

function getPatientIdFromKey(key) {
	const subKey = key.substr(PATIENT_CACHE_NAME.length);
	return +subKey.substr(0, subKey.indexOf("_"));
}

function getObjectKey(patientId, objectName) {
	return `${PATIENT_CACHE_NAME + patientId}_${objectName}`;
}

class PatientCache {
	constructor(storage) {
		this._storage = storage;
	}

	getValue = (patientId, objectName) => JSON.parse(this._storage.getItem(getObjectKey(patientId, objectName)));

	cachePatientData = (patientId, objectName, value) => {
		this.clearNotPatientData(patientId);
		this.saveValue(patientId, objectName, value);
		this._storage.setItem(getObjectKey(patientId, "updateTime"), new Date().getTime().toString());
	};

	evict = (patientId, objectName) => {
		const objectKey = getObjectKey(patientId, objectName);
		this.deleteValue(objectKey);
	};

	evictPatient = (patientId) => {
		this._storage.keys().forEach((key) => {
			if (key.startsWith(PATIENT_CACHE_NAME + patientId)) {
				this._storage.removeItem(key);
			}
		});
	};

	saveValue = (patientId, objectName, value) => {
		this._storage.setItem(getObjectKey(patientId, objectName), JSON.stringify(value));
	};

	deleteValue = (objectKey) => {
		this._storage.removeItem(objectKey);
	};

	clearNotPatientData(patientId) {
		this._storage.keys().forEach((key) => {
			if (key.startsWith(PATIENT_CACHE_NAME)) {
				const keyPatientId = getPatientIdFromKey(key);
				if (+keyPatientId !== +patientId) {
					this._storage.removeItem(key);
				}
			}
		});
	}
}

export default PatientCache;
