import "./polyfill";
import HomeProvider from "./homeProvider";
import MicroserviceHomeProvider from "./microserviceHomeProvider";
import PreferenceProvider from "./preferenceProvider";
import ApiAccess from "./apiAccess";
import PatientProvider from "./patientProvider";
import PatientPreferenceProvider from "./patientPreferencesProvider";
import PatientOverviewProvider from "./patientOverviewProvider";
import PatientOverviewFormsProvider from "./patientOverviewFormsProvider";
import FlowerChartProvider from "./flowerChartProvider";
import NexusVideoProvider from "./nexusVideoProvider";
import SchoolDataProvider from "./schoolDataProvider";
import EventBus from "./eventBus";
import Http from "./ajax";
import DefaultPageProvider from "./defaultPageProvider";
import StorageFactory from "./storage/storageFactory";

export default class NexusUiApiAccess {
	constructor(apiUrl, backendUrl, getAuthToken) {
		const apiHttp = new Http(apiUrl, getAuthToken);
		this._storage = StorageFactory.create();
		this._eventBus = new EventBus();
		this._homeProvider = new HomeProvider(apiHttp, this._storage, this._eventBus);
		this._microservicesHomeProviders = {};
		this._preferenceProvider = new PreferenceProvider(apiHttp, this._storage, this._eventBus);
		this._backendHttp = new Http(backendUrl, getAuthToken);
		this._defaultPageProvider = new DefaultPageProvider(this._preferenceProvider, this._backendHttp, this._storage, this._eventBus);
		this._apiAccess = new ApiAccess(this._backendHttp);
		this._patientProvider = new PatientProvider(this._backendHttp, this._homeProvider, this._storage, this._eventBus);
		this._patientPreferencesProvider = new PatientPreferenceProvider(this._backendHttp, this._patientProvider, this._storage, this._eventBus);
		this._patientOverviewProvider = new PatientOverviewProvider(this._backendHttp, this._patientProvider, this._storage, this._eventBus);
		this._patientOverviewFormsProvider = new PatientOverviewFormsProvider(this._backendHttp,
			this._patientProvider, this._storage, this._eventBus);
		this._flowerChartProvider = new FlowerChartProvider(this._backendHttp, this._patientOverviewProvider, this._storage, this._eventBus);
		this._nexusVideoProvider = new NexusVideoProvider(this._backendHttp, this._patientProvider, this._storage, this._eventBus);
		this._schoolDataProvider = new SchoolDataProvider(this._backendHttp, this._patientProvider, this._storage, this._eventBus);
	}

	getMicroservicesHome(homePath) {
		if (!this._microservicesHomeProviders[homePath]) {
			this._microservicesHomeProviders[homePath] = new MicroserviceHomeProvider(this._backendHttp, homePath, this._storage, this._eventBus);
		}
		return this._microservicesHomeProviders[homePath];
	}

	get home() {
		return this._homeProvider;
	}

	get preference() {
		return this._preferenceProvider;
	}

	get defaultPage() {
		return this._defaultPageProvider;
	}

	get eventBus() {
		return this._eventBus;
	}

	get storage() {
		return this._storage;
	}

	get patient() {
		return this._patientProvider;
	}

	get patientPreferences() {
		return this._patientPreferencesProvider;
	}

	get patientOverview() {
		return this._patientOverviewProvider;
	}

	get patientOverviewForms() {
		return this._patientOverviewFormsProvider;
	}

	get flowerChart() {
		return this._flowerChartProvider;
	}

	get nexusVideo() {
		return this._nexusVideoProvider;
	}

	get schoolData() {
		return this._schoolDataProvider;
	}

	get apiAccess() {
		return this._apiAccess;
	}
}
