export default class BaseProvider {
	constructor(objectName, storage, eventBus) {
		this._promise = null;
		this._eventBus = eventBus;
		this._objectName = objectName;
		this._objectNameCapitalize = objectName[0].toUpperCase() + objectName.slice(1);
		this._storage = storage;
	}

	evict() {
		this._storage.removeItem(`nexus_${this._objectName}`);
		this._eventBus.broadcast(`evict${this._objectNameCapitalize}`);
	}

	isCached() {
		return typeof this._storage.getItem(`nexus_${this._objectName}`) !== "undefined"
			&& this._storage.getItem(`nexus_${this._objectName}`) !== null;
	}

	getCached() {
		return JSON.parse(this._storage.getItem(`nexus_${this._objectName}`));
	}

	get() {
		const object = this._storage.getItem(`nexus_${this._objectName}`);

		if (object !== null) {
			return Promise.resolve(JSON.parse(object));
		}
		if (this._promise === null) {
			this._eventBus.broadcast(`startLoading${this._objectNameCapitalize}`);
			this._promise = this.fetchObject()
				.then((result) => this.broadcastFinishLoadingObject(result))
				.then(JSON.parse)
				.then((result) => this.cacheObject(result));
		}
		return this._promise;
	}

	cacheObject(object) {
		/* REMEMBER: JSON.stringify(null) === "null" && JSON.stringify(undefined) === "undefined" */
		/* It saves the value as a string, also the empty values eg: "null", "undefined" */
		this._storage.setItem(`nexus_${this._objectName}`, JSON.stringify(object));
		this._promise = null;
		return object;
	}

	broadcastFinishLoadingObject(object) {
		this._eventBus.broadcast(`finishLoading${this._objectNameCapitalize}`);
		return object;
	}

	fetchObject() {
		throw new Error(`Not implemented${this._objectName}`);
	}
}
