function getUrl(baseUrl, url) {
	const re = new RegExp("^(http|https)://", "i");
	if (re.test(url)) {
		return url;
	}
	return baseUrl + url;
}

function decorateError(error, status, responseText) {
	const decoratedError = error;
	decoratedError.response = {
		status,
		data: {
			reason: responseText,
			message: error.message
		}
	};

	return decoratedError;
}

export default class Http {
	constructor(apiUrl, getAuthToken) {
		this._getAuthToken = getAuthToken;
		this._apiUrl = apiUrl;
	}

	get(url, cancellationToken) {
		return new Promise((resolve, reject) => {
			const req = new XMLHttpRequest();
			req.open("GET", getUrl(this._apiUrl, url));
			req.setRequestHeader("Authorization", this._getAuthToken());
			req.setRequestHeader("Cache-Control", "no-cache, no-store, must-revalidate");
			req.setRequestHeader("Pragma", "no-cache");
			req.onload = function onLoad() {
				if (req.status >= 200 && req.status <= 299) {
					resolve(req.response);
				} else {
					reject(decorateError(new Error(req.statusText), req.status, req.responseText));
				}
			};

			if (cancellationToken) {
				cancellationToken.cancel = function cancel() { // eslint-disable-line no-param-reassign
					req.abort();
				};
			}

			req.onerror = function onError() {
				reject(decorateError(new Error("Network error")));
			};

			req.send();
		});
	}
}
