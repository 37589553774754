import SnappyJS from "snappyjs";
import "fast-text-encoding";

function ensureString(argument) {
	const isString = typeof argument === "string" || argument instanceof String;
	if (!isString) {
		throw TypeError(`Only strings are supported by StringCompressor but got: ${typeof argument}`);
	}
}

const isIE = !!document.documentMode;

class StringCompressor {
	encoder = new TextEncoder();

	decoder = new TextDecoder();

	static isAvailable() {
		return !isIE && typeof Uint8Array !== "undefined" && typeof TextDecoder !== "undefined" && typeof TextEncoder !== "undefined";
	}

	utf8StrDecode = (array8) => this.decoder.decode(array8);

	utf8StrEncode = (str) => this.encoder.encode(str);

	array8ToStr = (array8) => {
		const pageSize = 1000;
		let str = "";
		let cursor = 0;

		while (cursor < array8.length) {
			const slice = array8.subarray(cursor, cursor + pageSize);
			cursor += slice.length;
			str += String.fromCharCode.apply(null, slice);
		}
		return str;
	};

	strToArray8 = (str) => {
		const array8 = new Uint8Array(str.length);
		for (let i = 0; i < str.length; i += 1) {
			const charCode = str.charCodeAt(i);
			array8[i] = charCode;
			if (array8[i] !== charCode) {
				throw Error("strToArray8 function is applied for string with utf-8 characters");
			}
		}

		return array8;
	};

	compress = (uncompressedStr) => {
		ensureString(uncompressedStr);

		const uncompressedArray = this.utf8StrEncode(uncompressedStr);
		const compressedArray = SnappyJS.compress(uncompressedArray);
		return this.array8ToStr(compressedArray);
	};

	uncompress = (compressedString) => {
		ensureString(compressedString);

		const compressedArray = this.strToArray8(compressedString);
		const uncompressedArray = SnappyJS.uncompress(compressedArray);
		return this.utf8StrDecode(uncompressedArray);
	};
}

export default StringCompressor;
