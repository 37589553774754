import { isDefined, getLinkHref } from "./utils";
import PatientCache from "./patientCache";
import PromiseCache from "./promiseCache";

export default class PatientProvider {
	constructor($http, homeProvider, storage, eventBus) {
		this._$http = $http;
		this._eventBus = eventBus;
		this._homeProvider = homeProvider;
		this.promiseCache = new PromiseCache();
		this.patientCache = new PatientCache(storage);
	}

	get(patientId) {
		const patientData = this.patientCache.getValue(patientId, "patient");
		if (isDefined(patientData)) {
			return Promise.resolve(patientData);
		}

		if (this.promiseCache.contains(patientId)) {
			return this.promiseCache.get(patientId);
		}

		return this._homeProvider.get()
			.then((home) => {
				if (this.promiseCache.contains(patientId)) {
					return this.promiseCache.get(patientId);
				}

				const patientByIdHref = getLinkHref(home, "patientById");
				const patientPromise = this._$http.get(`${patientByIdHref}?id=${patientId}`)
					.then(JSON.parse)
					.then(this.fetchPatientImage())
					.then(this.finishFetchPatientData(patientId));

				this.promiseCache.put(patientId, patientPromise);
				return patientPromise;
			});
	}

	fetchPatientImage() {
		return (patient) => {
			const imgHref = getLinkHref(patient, "imageThumbnail");
			if (imgHref) {
				return this._$http.get(imgHref).then((image) => ({
					...patient,
					image
				})).catch((fetchImageError) => ({
					...patient,
					fetchImageError
				}));
			}
			return patient;
		};
	}

	finishFetchPatientData(patientId) {
		/* eslint-disable prefer-destructuring */
		const promiseCache = this.promiseCache;
		return (patient) => {
			promiseCache.clear(patientId);
			this.patientCache.cachePatientData(patientId, "patient", patient);
			return patient;
		};
	}

	evict(patientId) {
		this.patientCache.evict(patientId, "patient");
		this._eventBus.broadcast("patientEvictedEvent", { patientId });
	}

	evictAllPatientData(patientId) {
		if (patientId) {
			this.patientCache.evictPatient(patientId);
		}
	}
}
