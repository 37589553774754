import BaseProvider from "./baseProvider";
import { getLinkHref } from "./utils";

function ofContext(preferences, contextName) {
	const contextPreferences = preferences[contextName];
	if (Array.isArray(contextPreferences)) {
		return {
			default: contextPreferences.find((contextPreference) => contextPreference.defaultPreference === true)
		};
	}
	return null;
}

const DEFAULT_PAGE_CONTEXT_NAME = "DEFAULT_PAGE";
const DEFAULT_PAGE_LINK_NAME = "self";

export default class DefaultPageProvider extends BaseProvider {
	constructor(patientPreferenceProvider, $http, storage, eventBus) {
		super("defaultPage", storage, eventBus);
		this._patientPreferenceProvider = patientPreferenceProvider;
		this._$http = $http;
	}

	fetchObject() {
		return this._patientPreferenceProvider.get()
			.then((patientPreferences) => this.getDefaultFilter(patientPreferences));
	}

	getDefaultFilter(patientPreferences) {
		const defaultFilter = ofContext(patientPreferences, DEFAULT_PAGE_CONTEXT_NAME).default;
		if (defaultFilter) {
			return this._$http.get(getLinkHref(defaultFilter, DEFAULT_PAGE_LINK_NAME));
		}
		return null;
	}
}
