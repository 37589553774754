import StringCompressor from "./stringCompressor";

function getDefaultStorage() {
	return sessionStorage;
}

const KEY_PREFIX = "compressed_";

class CompressingStorage {
	constructor(storage = getDefaultStorage()) {
		this.storage = storage;
		this.compressor = new StringCompressor();
	}

	static isAvailable() {
		return StringCompressor.isAvailable();
	}

	nameToKey = (name) => KEY_PREFIX + name;

	keyToName = (key) => (key.startsWith(KEY_PREFIX) ? key.substr(KEY_PREFIX.length) : key);

	setItem = (name, value) => {
		this.storage.setItem(this.nameToKey(name), this.compressor.compress(value));
	}

	getItem = (name) => {
		const compressedValue = this.storage.getItem(this.nameToKey(name));
		return compressedValue !== null ? this.compressor.uncompress(compressedValue) : null;
	}

	removeItem = (name) => this.storage.removeItem(this.nameToKey(name));

	clear = () => this.storage.clear();

	keys = () => Object.keys(this.storage).map(this.keyToName);
}

export default CompressingStorage;
