export default class EventBus {
	constructor() {
		this.broadcastFunctions = [];
		this.cachedEvents = [];
	}

	registerBroadcastFunction(broadcastFunction) {
		this.broadcastFunctions.push(broadcastFunction);
	}

	/* eslint-disable-next-line	class-methods-use-this */
	on(eventName, callback) {
		return document.addEventListener(eventName, callback);
	}

	broadcast(eventName, detail = {}) {
		let event;
		try {
			event = new CustomEvent(eventName, {
				detail,
				bubbles: true,
				cancelable: true
			});
		} catch (e) {
			event = document.createEvent("CustomEvent");
			event.initCustomEvent(eventName, false, false, detail);
		}

		document.dispatchEvent(event);

		this.broadcastFunctions.forEach((broadcastFunction) => {
			broadcastFunction(eventName, detail);
		});
	}
}
